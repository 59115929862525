/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@mixin mbp($point) {
	@media (min-width: #{$point}px)  { @content; }
}

@mixin bp($point) {
	@media (max-width: #{$point}px)  { @content; }
}

@function stripUnit($value) {
	@return $value / ($value * 0 + 1);
}

@function rem($value) {
	@return #{stripUnit($value) / 16}rem;
}